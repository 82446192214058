import Vue from "vue"
import Vuex from "vuex"
import api from "@/services/api"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    page: {},

    site_id: "bassetlawtriclub",
    settings: null,
    main_pages: null,
    menu_pages: null,

    homeID: "-yFnTgPRd",
    aboutID: "1HnQfhtBP",
    triathlonID: "EHe9cWyQ6",
    newsID: "eNFdt-NhR",
    offersID: "raV3k7yWr",
    privacyID: "Xog63MYsc",
    contactID: "LT1UEQB9R",
    trainingID: "b4suJ7YgS",
    kitID: "RoLIH4o05",
    faqsID: "wdpxOYlWu",

    newsPage: null,
    privacyPage: null
  },
  getters: {
    isHomePage(state) {
      return state.page.ItemID && state.page.ItemID == state.homeID
    },
    isAboutPage(state) {
      return state.page.ItemID && state.page.ItemID == state.aboutID
    },
    isContactPage(state) {
      return state.page.ItemID && state.page.ItemID == state.contactID
    },
    isTriathlonPage(state) {
      return state.page.ItemID && state.page.ItemID == state.triathlonID
    },
    isNewsPage(state) {
      return state.page.ItemID && state.page.ItemID == state.newsID
    },
    isOffersPage(state) {
      return state.page.ItemID && state.page.ItemID == state.offersID
    },
    isTrainingPage(state) {
      return state.page.ItemID && state.page.ItemID == state.trainingID
    },
    isKitPage(state) {
      return state.page.ItemID && state.page.ItemID == state.kitID
    },
    isFaqsPage(state) {
      return state.page.ItemID && state.page.ItemID == state.faqsID
    },
    isArticle(state) {
      return state.page.ItemType && state.page.ItemType == "page.articles"
    }
  },
  mutations: {
    setPage(state, page) {
      state.page = page
    },
    setSettings(state, settings) {
      state.settings = settings
    },
    setMainPages(state, main_pages) {
      state.main_pages = main_pages
      state.menu_pages = main_pages.filter(p => p.ItemID != state.privacyID)
    }
  },
  actions: {
    getSettings({ commit, state }) {
      return new Promise(resolve => {
        if (!state.settings)
          api.getPageByItemID("SETTINGS").then(settings => {
            commit("setSettings", settings)
            resolve()
          })
        else resolve()
      })
    },
    getMainPages({ commit, state }) {
      return new Promise(resolve => {
        if (!state.main_pages)
          api.getPages("", "page.main").then(main_pages => {
            commit("setMainPages", main_pages)
            resolve()

            var promises = []

            main_pages.forEach(page => {
              if (page.ItemID == state.newsID) state.newsPage = page
              if (page.ItemID == state.privacyID) state.privacyPage = page
              // promises.push(api.getPages("", "page.sub=@" + page.ItemID).then(subs => Vue.set(page, "subs", subs)))
            })

            Promise.all(promises).then(() => {
              commit("setMainPages", main_pages)
            })
          })
        else resolve()
      })
    }
  }
})
