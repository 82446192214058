import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: "/",
    //   name: "index",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/about",
    //   name: "about",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/kit",
    //   name: "kit",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/triathlon",
    //   name: "triathlon",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/training",
    //   name: "training",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/news",
    //   name: "news",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/faqs",
    //   name: "faqs",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/offers",
    //   name: "offers",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/contact",
    //   name: "contact",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/article",
    //   name: "article",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/privacy",
    //   name: "privacy",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    {
      path: "/",
      name: "root",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/404-not-found",
      name: "404",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/:url",
      name: "page-by-url",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
