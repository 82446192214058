import axios from "axios"
import store from "@/store"
import dynamodb from "aws-sdk/clients/dynamodb"

var request = axios.create({
  baseURL:
    window.location.hostname == "localhost"
      ? "https://eiu3du1gbi.execute-api.eu-west-2.amazonaws.com/test/"
      : "https://eiu3du1gbi.execute-api.eu-west-2.amazonaws.com/live/",
  headers: { "Content-Type": "application/json" }
})

function parseItem(item) {
  if (item.ItemType) {
    var data = item.ItemType.split("#")
    if (data[1]) item.OrderIndex = data[1]

    data = data[0].split("@")
    if (data[1]) item.ParentID = data[1]

    item.ItemType = data[0].replace(/=+$/g, "")
  }

  return item
}

export default {
  getPages: function(
    ProjectionExpression = "",
    ItemType,
    FilterExpression = "",
    ExpressionAttributeNames = "",
    ExpressionAttributeValues = ""
  ) {
    return new Promise((resolve, reject) => {
      var params = {
        ProjectionExpression: "#ItemID, #name, #url" + (ProjectionExpression ? "," + ProjectionExpression : ""),
        TableName: "cms",
        IndexName: "SiteID-ItemType-index",
        KeyConditionExpression: "SiteID = :siteid and begins_with ( ItemType, :itemtype )",
        FilterExpression:
          "#active = :true and (#hide = :false or attribute_not_exists(#hide))" +
          (FilterExpression ? " and " + FilterExpression : ""),
        ExpressionAttributeNames: Object.assign(
          {
            "#ItemID": "ItemID",
            "#name": "name",
            "#url": "url",
            "#hide": "hide",
            "#active": "active"
          },
          ExpressionAttributeNames
        ),
        ExpressionAttributeValues: Object.assign(
          {
            ":siteid": dynamodb.Converter.input(store.state.site_id),
            // Add '=' to end of item type, or '#' if '=' already present
            ":itemtype": dynamodb.Converter.input(ItemType + (ItemType.indexOf("=") > -1 ? "#" : "=")),
            ":false": dynamodb.Converter.input(false),
            ":true": dynamodb.Converter.input(true)
          },
          ExpressionAttributeValues
        )
      }

      request
        .post("getitems?siteid=" + store.state.site_id, params)
        .then(response => {
          resolve(response.data.Items.map(item => dynamodb.Converter.unmarshall(item)))
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getPageByURL: function(url) {
    return new Promise((resolve, reject) => {
      request
        .get("getpage?siteid=" + store.state.site_id + "&url=" + url)
        .then(response => {
          resolve(parseItem(dynamodb.Converter.unmarshall(response.data.Items[0])))
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getHomePage: function() {
    return new Promise((resolve, reject) => {
      request
        .get("getitem?siteid=" + store.state.site_id + "&itemid=" + store.state.homeID)
        .then(response => resolve(parseItem(dynamodb.Converter.unmarshall(response.data.Item))))
        .catch(error => {
          reject(error)
        })
    })
  },
  getPageByItemID: function(itemid, attr = "") {
    return new Promise((resolve, reject) => {
      request
        .get("getitem?siteid=" + store.state.site_id + "&itemid=" + itemid + (attr ? "&attr=" + attr : ""))
        .then(response => resolve(parseItem(dynamodb.Converter.unmarshall(response.data.Item))))
        .catch(error => {
          reject(error)
        })
    })
  },
  sendEmail: function(subject, content) {
    return new Promise((resolve, reject) => {
      request
        .post(
          "submit?siteid=" + store.state.site_id,
          JSON.stringify({
            personalizations: [{ to: [{ email: "bassetlawtriclub@hotmail.co.uk" }], subject: subject }],
            from: { email: "website@bassetlawtriclub.co.uk" },
            content: [{ type: "text/html", value: content }]
          })
        )
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
