import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Meta from "vue-meta"
import VModal from "vue-js-modal"
import VueAnalytics from "vue-analytics"

import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faBars,
  faTimes,
  faChevronUp,
  faChevronDown,
  faEnvelope,
  faSwimmer,
  faBiking,
  faRunning,
  faSnowflake,
  faSun
} from "@fortawesome/free-solid-svg-icons"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

library.add(
  faBars,
  faTimes,
  faChevronUp,
  faChevronDown,
  faFacebook,
  faInstagram,
  faEnvelope,
  faSwimmer,
  faBiking,
  faRunning,
  faSnowflake,
  faSun
)
Vue.component("font-awesome-icon", FontAwesomeIcon)

const requireComponent = require.context("./components/elements", false, /[\w-]+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1")
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.config.productionTip = false
const isProd = process.env.NODE_ENV === "production"

Vue.use(VModal)
Vue.use(Meta)

Vue.use(VueAnalytics, {
  id: "UA-174755290-1",
  router,
  debug: {
    enabled: false,
    sendHitTask: isProd
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
